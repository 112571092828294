import { ModsPanelCompProps } from "./ModsPanel";
import { useEffect, useState } from "react";
import {
  Space,
  Radio,
  Input,
  Row,
  Col,
  Tooltip,
  Select,
  Checkbox,
  Switch,
  InputNumber,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  COMMON_PATHS,
  IDynamicChangeMod,
  findLayersOrLayerAssets,
  getPath,
} from "@blings/blings-player";
import { modTypes } from "@blings/blings-player";
import { observer } from "mobx-react-lite";
import { JSONTreeSelect } from "../../BaseComps/JSONTreeSelect";
import { rootStore, useMst } from "../../stores/main";
import { theme } from "../../theme";
import { JsEditor } from "../../components/Editor/JsEditor";
import { InfoTooltip } from "../../BaseComps/InfoTooltip";
import { ColorPicker } from "../../BaseComps/ColorPicker";
import "./DynamicValueMod.scss";
import { ExperimentOptimizationTechnique } from "../../API";
import { DividerWithText } from "../../BaseComps/HorizontalDividerName";
import { AiIcon, ExperimentPlusIcon } from "../../icons-and-animations/Icons";

import UploadWithTextarea from "../Upload";
const { Option } = Select;
export enum DynamicValueModTypes {
  input = "input",
  textarea = "textarea",
  number = "number",
  color = "color",
  boolean = "boolean",
  url = "url",
  media = "media",
  hex = "hex",
  cta = "cta",
  date = "date",
  percentage = "percentage",
}

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props<T extends modTypes> {
  index: ModsPanelCompProps<any>["index"];
  onChange: ModsPanelCompProps<IDynamicChangeMod<T, any>>["onChange"];
  type?: DynamicValueModTypes;
  change: ModsPanelCompProps<IDynamicChangeMod<T, any>>["change"];
  uploaderProps?: { accept: string; s3Folder: string };
  booleanText?: [string, string];
  jsonVid?: any;
  assetName?: string;
  onChangeType?: (type: dynamicValueType) => void;
  // onChange: (index: number, key: keyof T, value: any) => void;
}
export type dynamicValueType =
  | "value"
  | "dataKey"
  | "expression"
  | "liveControlKey"
  | "inputName"
  | "ctaName"
  | "exposeToPlatform"
  | "experimentId";

// defaultValue
function DynamicValueModComp<T extends modTypes>({
  index,
  onChange,
  change,
  type = DynamicValueModTypes.input,
  uploaderProps,
  booleanText,
  jsonVid,
  assetName,
  onChangeType,
}: Props<T>) {
  const isMediaMod = change.type.startsWith("asset");
  const isColorMod = change.type.startsWith("themeColor");
  const isTextMod = change.type.startsWith("text");
  const isCountdownMod = change.type.startsWith("countdown");
  const isTransform = change.type.startsWith("transform");
  const canExposeToPlatform = rootStore.platformStore.isControlDeprecated;
  const hasExperimentsEnabled =
    rootStore.experimentStore.selectedExperimentOptimizationTechnique &&
    rootStore.experimentStore.selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;

  const experimentId = change.experimentId;
  const experimentData =
    experimentId !== undefined
      ? rootStore.experimentStore.getExperiment(experimentId)
      : undefined;
  const experimentVariants =
    experimentId !== undefined ? experimentData?.variants : undefined;
  const hasExperiment =
    experimentId !== undefined &&
    experimentVariants &&
    experimentVariants.length >= 2;
  const [isExposedToPlatform, setIsExposedToPlatform] = useState<
    boolean | undefined
  >(change.exposeToPlatform);

  const [value, setValue] = useState(change.value);
  const [dataKey, setDataKey] = useState(change.dataKey);
  const [liveControlKey, setLiveControlKey] = useState(change.liveControlKey);
  const [inputName, setInputName] = useState(change.inputName);
  const [expression, setExpression] = useState(change.expression);

  const [valueSetterType, setValueType] = useState(() => {
    if (typeof change["value"] !== "undefined") {
      onChangeType?.("value");
      return "value";
    } else if (typeof change["dataKey"] !== "undefined") {
      // dataKey is the key for perVideo data
      onChangeType?.("dataKey");
      return "dataKey";
    } else if (typeof change["liveControlKey"] !== "undefined") {
      onChangeType?.("liveControlKey");
      return "liveControlKey";
    } else if (typeof change["expression"] !== "undefined") {
      onChangeType?.("expression");
      return "expression";
    } else if (typeof change["inputName"] !== "undefined") {
      onChangeType?.("inputName");
      return "inputName";
    } else if (isCountdownMod || isTransform) {
      onChangeType?.("value");
      return "value";
    } else {
      onChangeType?.("dataKey");
      return "dataKey";
    }
  });
  const [update, setUpdate] = useState<number>(0);

  const handleValueTypeChange = (changeType: dynamicValueType) => {
    switch (valueSetterType) {
      case "value":
        setValue(change.value);
        break;
      case "dataKey":
        setDataKey(change.dataKey);
        break;
      case "liveControlKey":
        setLiveControlKey(change.liveControlKey);
        break;
      case "inputName":
        setInputName(change.inputName);
        break;
      case "expression":
        setExpression(change.expression);
        break;
      default:
        break;
    }

    setValueType(changeType);
    onChangeType?.(changeType);
    switch (changeType) {
      case "value":
        changeValWithValueSetterType(value, changeType);
        break;
      case "dataKey":
        changeValWithValueSetterType(dataKey, changeType);
        break;
      case "liveControlKey":
        changeValWithValueSetterType(liveControlKey, changeType);
        break;
      case "inputName":
        changeValWithValueSetterType(inputName, changeType);
        break;
      case "expression":
        changeValWithValueSetterType(expression, changeType);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      valueSetterType === "value" &&
      change["value"] === undefined &&
      type === DynamicValueModTypes.textarea
    ) {
      const layers = findLayersOrLayerAssets({
        assetId: change["assetId"],
        layerName: change["layerName"],
        layerUid: change["layerUid"],
        jsonVid,
      });
      if (layers?.length) {
        const layer = layers[0];
        const text: string = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER); // If the layer has text
        changeVal(text);
      }
    }
    if (valueSetterType !== "value") {
      changeExposeToPlatformVal(false);
      setIsExposedToPlatform(false);
    }
  }, [change, jsonVid, type, valueSetterType]);

  function changeValWithValueSetterType(value, updatedValueSetterType) {
    const types: Array<dynamicValueType> = [
      "value",
      "dataKey",
      "expression",
      "liveControlKey",
      "inputName",
    ];
    onChange(
      index,
      types,
      types.map((t) => (t === updatedValueSetterType ? value : undefined))
    );
  }

  function changeModExperimentId(experimentId: string) {
    const types: Array<dynamicValueType> = ["experimentId"];
    onChange(index, types, [experimentId]);
  }

  function changeVal(value) {
    changeValWithValueSetterType(value, valueSetterType);
  }

  function changeExposeToPlatformVal(value) {
    const types: Array<dynamicValueType> = ["exposeToPlatform"];
    onChange(index, types, [value]);
  }

  function changeDefaultVal(defaultValue) {
    onChange(index, ["defaultValue"], [defaultValue]);
  }

  const {
    platformStore: { inputNames, uploadAssetToProject },
    dynamicDataStore: {
      perVideoCurrentData: perVideoData,
      liveControlCurrentData: liveControlData,
    },
  } = useMst();
  const projectJsonPerVideoData = perVideoData;
  const projectJsonLiveControlData = liveControlData;

  const inputNameOption = Array.from(new Set(inputNames)).map((inputName) => (
    <Option key={inputName}>{inputName}</Option>
  ));

  const DynamicValueBtn = (
    { info, title, value, disabled } = {
      info: "",
      title: "",
      value: "",
      disabled: false,
    } as any
  ) => (
    <Tooltip
      overlayClassName={"DynamicValueBtn-tooltip"}
      arrowContent={<span>aa</span>}
      // align={{points: ['tr','tr']}}
      arrow={{ pointAtCenter: true }}
      title={info}
      mouseEnterDelay={1}
      color={theme.blings_purple + "ee"}
    >
      <Radio.Button value={value} disabled={disabled} className="text-icon">
        {title}
      </Radio.Button>
    </Tooltip>
  );
  const getTypeFromAssetName = (assetName: string): string => {
    if (assetName) {
      assetName = assetName.toLowerCase(); // Convert to lowercase
      if (assetName.endsWith(".mp4")) return "video/mp4";
      if (assetName.endsWith(".mp3")) return "audio/mp3";
      if (assetName.endsWith(".jpg") || assetName.endsWith(".jpeg"))
        return "image/jpeg";
      if (assetName.endsWith(".png")) return "image/png";
      if (assetName.endsWith(".webp")) return "image/webp";
    }
    return "";
  };
  const showInput =
    type === DynamicValueModTypes.input ||
    type === DynamicValueModTypes.url ||
    type === DynamicValueModTypes.media ||
    type === DynamicValueModTypes.hex ||
    type === DynamicValueModTypes.cta;

  const [inputPlaceholder, expressionPlaceholder] = (() => {
    switch (type) {
      case DynamicValueModTypes.number:
      case DynamicValueModTypes.percentage:
        return ["10", `return data.number`];
      case DynamicValueModTypes.hex:
        return [
          "#ffaa01",
          `return data.userLikesPink ? "${theme.primary}" : "#fff"`,
        ];
      case DynamicValueModTypes.url:
      case DynamicValueModTypes.media:
        return ["URL", "return 'https://cats.com/assets/' + data.cat"];
      case DynamicValueModTypes.boolean:
        return ["true or false", "return !data.value"];
      case DynamicValueModTypes.input:
      default:
        // eslint-disable-next-line no-template-curly-in-string
        return ["Hi ${name}", "return 'Hi ' + data.name"];
    }
  })();
  return (
    <Space direction="vertical" className={"DynamicValueModComp"}>
      <div>
        <DividerWithText text={"Data Source"} />
      </div>
      <Radio.Group
        className={"dynamic-value-tab-selector"}
        value={valueSetterType}
        onChange={(e) => handleValueTypeChange(e.target.value)}
        buttonStyle="solid"
      >
        {DynamicValueBtn({
          info:
            'Connect to data that can be dynamic for each video (e.g. "user_name")  ',
          value: "dataKey",
          title: "Data",
        })}
        {!canExposeToPlatform &&
          DynamicValueBtn({
            info:
              "Connect to data that can be controlled from Blings platform in real time (e.g. marketing copy, discount price)  ",
            value: "liveControlKey",
            title: "Control",
          })}
        {DynamicValueBtn({
          info: "Replace the video content with any custom value",
          value: "value",
          title: "Edit",
        })}
        {inputNames &&
          inputNames.length &&
          !isCountdownMod &&
          DynamicValueBtn({
            info: "Connect to input that can be dynamic from another scene",
            value: "inputName",
            title: "Input",
          })}
        {DynamicValueBtn({
          info:
            "Write down Javascript code that `return` a value. You can use the `data` object",
          value: "expression",
          title: (
            <div style={{ display: "flex" }}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 9L11 6L8 3"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 3L1 6L4 9"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ),
        })}
      </Radio.Group>
      {valueSetterType === "value" && (
        <div className="expose-to-platform">
          {canExposeToPlatform &&
            (isMediaMod || isColorMod || isTextMod || isCountdownMod) && (
              <div className="expose-to-platform-checkbox">
                <Checkbox
                  disabled={isTextMod || isCountdownMod}
                  checked={isExposedToPlatform || isTextMod || isCountdownMod}
                  onChange={(e) => {
                    setIsExposedToPlatform(e.target.checked);
                    changeExposeToPlatformVal(e.target.checked);
                  }}
                />
                <span style={isTextMod ? { opacity: "0.5" } : {}}>
                  Enable editing in Blings Platform
                </span>
                <InfoTooltip
                  info={
                    isTextMod
                      ? "All text values are editable via the platform under 'Edit Video' -> 'Text'."
                      : isCountdownMod
                      ? "All Countdowns are editable via the platform under 'Custom Edits'."
                      : "Enable this option for easy value editing via Blings Platform under 'Edit Video' -> 'Custom Edits'."
                  }
                />
              </div>
            )}
        </div>
      )}
      {valueSetterType === "value" && (
        <Row style={{ display: "flex", alignItems: "center" }}>
          {hasExperimentsEnabled && hasExperiment ? (
            <div className="experiment-viewport">
              <div className="experiment-container">
                {experimentVariants.map((variant, index) => {
                  const variantIsSelectedToBeShowOnPlayer =
                    rootStore.experimentStore.getSelectedVariantForExperiment(
                      experimentId
                    ) !== undefined &&
                    variant.id ===
                      rootStore.experimentStore.getSelectedVariantForExperiment(
                        experimentId
                      );
                  return (
                    <div key={index}>
                      <DividerWithText
                        text={`Experiment ${index + 1}`}
                        {...(index >= 1 && {
                          onIconClick: () => {
                            rootStore.experimentStore.deleteVariantByIndex(
                              experimentId,
                              index
                            );
                            if (experimentVariants.length <= 1) {
                              changeModExperimentId("");
                              changeVal(experimentVariants[0].value);
                            }
                            setUpdate(update + 1);
                            rootStore.playerStore.runPlayer();
                          },
                        })}
                      />
                      {type === DynamicValueModTypes.hex && (
                        <div
                          id="color-picker-button"
                          style={{
                            marginRight: "0.5rem",
                            alignSelf: "center",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Input
                            size="large"
                            id="connector-input"
                            value={experimentVariants[index].value}
                            // eslint-disable-next-line no-template-curly-in-string
                            placeholder={inputPlaceholder}
                            onChange={(e) => {
                              setUpdate(update + 1);
                              const value = /^[1-9]\d*$/.test(e.target.value)
                                ? parseInt(e.target.value, 10)
                                : e.target.value; // If only digits without trailing 0, convert to number
                              rootStore.experimentStore.updateVariantByIndex(
                                experimentId,
                                index,
                                value.toString()
                              );
                              rootStore.playerStore.runPlayer();
                              // changeVal(value);
                            }}
                            allowClear={true}
                          />
                          <ColorPicker
                            color={experimentVariants[index].value || ""}
                            onChange={(e) => {
                              setUpdate(update + 1);
                              rootStore.experimentStore.updateVariantByIndex(
                                experimentId,
                                index,
                                e.hex
                              );
                              rootStore.playerStore.runPlayer();
                              // changeVal(e.hex);
                            }}
                            size={2}
                          />
                        </div>
                      )}

                      {type === DynamicValueModTypes.media && (
                        <UploadWithTextarea
                          uploadAssetsToProject={uploadAssetToProject}
                          onChange={(e) => {
                            setUpdate(update + 1);
                            const value = /^[1-9]\d*$/.test(e)
                              ? parseInt(e, 10)
                              : e; // If only digits without trailing 0, convert to number
                            rootStore.experimentStore.updateVariantByIndex(
                              experimentId,
                              index,
                              value.toString()
                            );
                            rootStore.playerStore.runPlayer();
                          }}
                          value={experimentVariants[index].value || ""}
                          mimeType={
                            assetName && getTypeFromAssetName(assetName)
                          }
                          placeholder={inputPlaceholder}
                        />
                      )}
                      {(type === DynamicValueModTypes.input ||
                        type === DynamicValueModTypes.url ||
                        type === DynamicValueModTypes.cta) && (
                        <Input
                          size="large"
                          id="connector-input"
                          value={experimentVariants[index].value}
                          // eslint-disable-next-line no-template-curly-in-string
                          placeholder={inputPlaceholder}
                          onChange={(e) => {
                            setUpdate(update + 1);
                            const value = /^[1-9]\d*$/.test(e.target.value)
                              ? parseInt(e.target.value, 10)
                              : e.target.value; // If only digits without trailing 0, convert to number
                            rootStore.experimentStore.updateVariantByIndex(
                              experimentId,
                              index,
                              value.toString()
                            );
                            rootStore.playerStore.runPlayer();
                            // changeVal(value);
                          }}
                          allowClear={true}
                        />
                      )}
                      {(type === DynamicValueModTypes.number ||
                        type === DynamicValueModTypes.percentage) && (
                        <InputNumber
                          size="large"
                          value={change.value}
                          onChange={(value) => {
                            setUpdate(update + 1);
                            rootStore.experimentStore.updateVariantByIndex(
                              experimentId,
                              index,
                              value
                            );
                            rootStore.playerStore.runPlayer();
                          }}
                          {...(type === DynamicValueModTypes.percentage
                            ? {
                                formatter: (value, info) => (value || 0) + "%",
                              }
                            : {})}
                          {...(type === DynamicValueModTypes.percentage
                            ? {
                                parser: (value) =>
                                  Number.parseFloat(
                                    value?.replace("%", "") || "0"
                                  ),
                              }
                            : {})}
                        />
                      )}
                      {type === DynamicValueModTypes.boolean && (
                        <>
                          <Switch checked={change.value} onChange={changeVal} />{" "}
                          {booleanText &&
                            (change.value ? booleanText[0] : booleanText[1])}
                        </>
                      )}

                      {type === DynamicValueModTypes.textarea && (
                        <Input.TextArea
                          id="connector-input"
                          placeholder={inputPlaceholder}
                          value={experimentVariants[index].value}
                          onChange={(e) => {
                            rootStore.experimentStore.updateVariantByIndex(
                              experimentId,
                              index,
                              e.target.value
                            );
                            setUpdate(update + 1);
                            rootStore.playerStore.runPlayer();
                          }}
                        />
                      )}
                    </div>
                  );
                })}
                <div
                  className="variant-add-div"
                  onClick={() => {
                    rootStore.experimentStore.createNewVariant(
                      experimentId,
                      ""
                    );
                    setUpdate(update + 1);
                    rootStore.playerStore.runPlayer();
                  }}
                >
                  <div className="experiment-option-add-div">
                    <ExperimentPlusIcon />
                  </div>
                  <span className="add-variant-text">Add Experiment</span>
                  <div className="ai-icon"></div>
                  <AiIcon />
                </div>
              </div>
            </div>
          ) : (
            <>
              <Col span={uploaderProps ? 21 : 24} style={{ display: "flex" }}>
                {type === DynamicValueModTypes.date && (
                  <div id="date-picker">
                    <DatePicker
                      size={"large"}
                      onChange={(x, dateString) =>
                        changeVal(
                          dayjs(x).tz(dayjs.tz.guess()).format().toString()
                        )
                      }
                      showTime
                      allowClear={false}
                      showNow={false}
                      needConfirm={false}
                      value={change.value && dayjs(change.value)}
                      variant="outlined"
                    />
                  </div>
                )}
                {showInput &&
                  (type === DynamicValueModTypes.media ? (
                    <UploadWithTextarea
                      uploadAssetsToProject={uploadAssetToProject}
                      onChange={(e) => {
                        const value = /^[1-9]\d*$/.test(e)
                          ? parseInt(e, 10)
                          : e; // If only digits without trailing 0, convert to number
                        changeVal(value);
                      }}
                      value={change.value}
                      mimeType={assetName && getTypeFromAssetName(assetName)}
                      placeholder={inputPlaceholder}
                    />
                  ) : (
                    <Input
                      size={"large"}
                      id="connector-input"
                      value={change.value}
                      // eslint-disable-next-line no-template-curly-in-string
                      placeholder={inputPlaceholder}
                      onChange={(e) => {
                        const value = /^[1-9]\d*$/.test(e.target.value)
                          ? parseInt(e.target.value, 10)
                          : e.target.value; // If only digits without trailing 0, convert to number
                        changeVal(value);
                      }}
                      allowClear={true}
                    />
                  ))}
                {(type === DynamicValueModTypes.number ||
                  type === DynamicValueModTypes.percentage) && (
                  <InputNumber
                    size={"large"}
                    value={change.value}
                    onChange={(value) => changeVal(value)}
                    {...(type === DynamicValueModTypes.percentage
                      ? { formatter: (value, info) => (value || 0) + "%" }
                      : {})}
                    {...(type === DynamicValueModTypes.percentage
                      ? {
                          parser: (value) =>
                            Number.parseFloat(value?.replace("%", "") || "0"),
                        }
                      : {})}
                  />
                )}
                {type === DynamicValueModTypes.textarea && (
                  <Input.TextArea
                    size={"large"}
                    id="connector-input"
                    placeholder={inputPlaceholder}
                    value={change.value}
                    onChange={(e) => {
                      changeVal(
                        e.target.value != null ? e.target.value : undefined
                      );
                    }}
                  />
                )}
                {type === DynamicValueModTypes.boolean && (
                  <>
                    <Switch checked={change.value} onChange={changeVal} />{" "}
                    {booleanText &&
                      (change.value ? booleanText[0] : booleanText[1])}
                  </>
                )}
                {type === DynamicValueModTypes.hex && (
                  <div id="color-picker-button">
                    <ColorPicker
                      color={change.value || ""}
                      onChange={(e) => changeVal(e.hex)}
                      size={2}
                    />
                  </div>
                )}
              </Col>
              {hasExperimentsEnabled &&
                (type === DynamicValueModTypes.textarea ||
                  type === DynamicValueModTypes.hex ||
                  showInput) && (
                  <div className="initial-add-option">
                    <div
                      className="variant-add-div"
                      onClick={() => {
                        const vId = rootStore.experimentStore.createNewExperiment(
                          change.value,
                          ""
                        );
                        changeModExperimentId(vId);
                        setUpdate(update + 1);
                        rootStore.playerStore.runPlayer();
                      }}
                    >
                      <div className="experiment-option-add-div">
                        <ExperimentPlusIcon />
                      </div>
                      <span className="add-variant-text">Add Variant</span>
                      <div className="ai-icon"></div>
                      <AiIcon />
                    </div>
                  </div>
                )}
            </>
          )}
        </Row>
      )}

      {valueSetterType === "dataKey" && (
        <label>
          <JSONTreeSelect
            className={"mod-form-json-tree-select"}
            json={projectJsonPerVideoData}
            value={change.dataKey}
            onChange={changeVal}
            allowClear
          />
        </label>
      )}

      {valueSetterType === "inputName" && (
        <label>
          <Select
            showSearch
            className={"mod-form-json-tree-select"}
            style={{ width: "100%" }}
            size={"large"}
            value={change.inputName}
            placeholder="Please select"
            onChange={changeVal}
          >
            {inputNameOption}
          </Select>
        </label>
      )}

      {valueSetterType === "liveControlKey" && ( // DEPRECATED. SHOULD ONLY BE USED FOR OLD PROJECTS
        <label>
          <JSONTreeSelect
            className={"mod-form-json-tree-select"}
            json={projectJsonLiveControlData}
            value={change.liveControlKey}
            onChange={changeVal}
            allowClear
          />
        </label>
      )}
      {valueSetterType === "expression" && (
        <JsEditor
          defaultValue={change.expression || expressionPlaceholder}
          onChange={(e: any) => changeVal(e)}
          tooltipText="This function will run every time the user interacts with the layer\s. You can use `data` and `control` objects."
        />
      )}
      {!(valueSetterType === "value" && !/\$\{.*?\}/.test(change.value)) && (
        <Input
          className="connector-default-input"
          addonBefore={"Default Value"}
          value={change.defaultValue}
          onChange={(e) => changeDefaultVal(e.target.value)}
          allowClear
          size="large"
        />
      )}
    </Space>
  );
}

export const DynamicValueMod = observer(DynamicValueModComp);

import { useState } from "react";
import "./GroupButtons.scss";
import {
  LayersIcon,
  FlowIcon,
  ImageIcon,
} from "../../icons-and-animations/Icons";

const iconMap = {
  LayersIcon: LayersIcon,
  FlowIcon: FlowIcon,
  ImageIcon: ImageIcon,
};

const GroupButtons = ({ items, initialActive, onChange, isLoading }) => {
  const [activeButton, setActiveButton] = useState(
    initialActive || items[0]?.value
  );
  const handleClick = (value) => {
    if (onChange(value)) {
      setActiveButton(value);
    }
  };

  const buttons = items.map((button) => {
    const IconComponent = iconMap[button.icon];
    return (
      <div className="btn-wrapper">
        <button
          key={button.value}
          value={button.path}
          style={{ padding: "0", height: "42px" }}
          className={`btn
            ${initialActive === button.value ? "btn-active" : "btn-inactive"}`}
          onClick={() => handleClick(button.path)}
        >
          {IconComponent && <IconComponent />}
          <span className="btn-title">{button.title}</span>
        </button>
        <div
          className="bottom-border"
          style={{
            width: initialActive === button.value ? "calc(100% + 24px)" : 0,
          }}
        ></div>
      </div>
    );
  });

  return (
    <div className="group-button-container">
      {isLoading ? <div className="loading"></div> : buttons}
    </div>
  );
};

export default GroupButtons;

import { Select } from "antd";
import { useEffect, useState } from "react";
import "./DemoWindow.scss";
import { createDemoPlayerDebounced } from "./DemoWindowHelpers";
import { SimpleLoader } from "../SimpleLoader";
import { DemoSdkExternalLink } from "./DemoSdkExternalLink";

export const DemoWindow = ({
  project,
  liveControlData,
  posterFrame,
  autoplay,
  openWin,
  height,
  selectedVideoPart,
}) => {
  const videoPartNames = project.videoParts?.map((vp) => vp.name || "");
  // if the project was just created project.stateJsonSchemaStr will be null.
  const data = project.stateJsonSchemaStr
    ? JSON.parse(project.stateJsonSchemaStr).examples[0]
    : {};
  const [scenes, setScenes] =
    useState<string[]>(
      (selectedVideoPart && [selectedVideoPart.name]) ||
        (videoPartNames && [videoPartNames[0]])
    ) || [];
  const [loaderDisplay, setLoaderDisplay] = useState(true);
  useEffect(() => {
    createDemoPlayerDebounced(
      setLoaderDisplay,
      scenes,
      project,
      liveControlData,
      data,
      autoplay,
      posterFrame
    );
    return () => {
      if ((window as any).dp) {
        (window as any).dp.destroy();
        (window as any).dp = null;
      }
    };
  }, [scenes]); // eslint-disable-line react-hooks/exhaustive-deps

  function closeNav(e: any) {
    if (!e.target.onclick) return false;
    e.stopPropagation();
    openWin(false);
  }
  return (
    <div id="DemoWin" className="overlay">
      {/* onClick={closeNav}> for Clicking on the window will close it*/}

      <div className="content">
        <span role="button" className="closebtn" onClick={closeNav}>
          &times;
        </span>
        <div className="demo-container-wrapper">
          <div id="demo-container"></div>
        </div>
        {loaderDisplay && (
          <div className="demo-loader-container">
            <SimpleLoader />
          </div>
        )}
      </div>
      <div className="ui-container">
        <div className="ui-in-Panel">
          <div className="add-scenes"> Add Scenes</div>
          <div className="ui-scenes">
            <Select
              id="demo-select"
              mode="multiple"
              allowClear
              onChange={setScenes}
              value={scenes}
            >
              {videoPartNames.map((vp) => (
                <Select.Option key={vp} value={vp}>
                  {vp}
                </Select.Option>
              ))}
            </Select>
            {
              <DemoSdkExternalLink
                project={project}
                scenes={scenes}
                data={data}
              ></DemoSdkExternalLink>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

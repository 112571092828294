import React from "react";
import { Button, Drawer, Tabs } from "antd";
import { DataSetter } from "./DataSetter";
import { LiveControlSetter } from "./LiveControlSetter";
import "./SchemaEditors.scss";
import { InfoTooltip } from "../../BaseComps/InfoTooltip";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import { observer } from "mobx-react-lite";
import { useMst } from "../../stores/main";
import { DownIcon, UpIcon } from "../../icons-and-animations/Icons";
import { BlingsButton } from "../BlingsButton/BlingsButton";

export const SchemaEditors = observer(() => {
  const {
    layoutStore: { schemaDrawerOpen, toggleSchemaDrawer },
    platformStore,
  } = useMst();

  return (
    <div
      className={["SchemaEditors", schemaDrawerOpen ? "opened" : ""].join(" ")}
    >
      <div className={"SchemaEditors-open-button"}>
        <BlingsButton
          onClick={() => {
            toggleSchemaDrawer();
          }}
          className="schema-drawer-button"
        >
          <>
            {schemaDrawerOpen ? <DownIcon /> : <UpIcon />}
            <div className="btn-text-child">MP5 Dynamic Data</div>
            {platformStore.isControlDeprecated ? (
              <InfoTooltip
                outline={true}
                info={
                  'Define the structure of the data that is unique to each and every video. e.g - "user_name" and data-base data'
                }
              />
            ) : (
              ""
            )}
          </>
        </BlingsButton>
      </div>
      <div style={{ position: "relative", height: "calc(100vh - 7rem)" }}>
        <Drawer
          placement="top"
          closable={false}
          onClose={() => toggleSchemaDrawer()}
          open={schemaDrawerOpen}
          getContainer={false}
          forceRender={true}
          rootStyle={{ position: "absolute" }}
          styles={{ content: { height: "calc(100vh - 7rem)" } }}
        >
          <ErrorBoundary>
            {platformStore.isControlDeprecated ? (
              <DataSetter />
            ) : (
              <ErrorBoundary>
                <Tabs
                  defaultActiveKey="pervideo"
                  type="card"
                  size={"middle"}
                  className="tabs"
                  items={[
                    {
                      key: "pervideo",
                      label: (
                        <span
                          style={{
                            display: platformStore.isControlDeprecated
                              ? "none"
                              : "block",
                          }}
                        >
                          <span className={"tab-text"}>Data</span>{" "}
                          <InfoTooltip
                            info={
                              'Define the structure of the data that is unique to each and every video. e.g - "user_name" and data-base data'
                            }
                          />
                        </span>
                      ),
                      children: <DataSetter />,
                    },
                    {
                      key: "livecontrol",
                      label: (
                        <span>
                          <span className={"tab-text"}>Control </span>{" "}
                          <InfoTooltip
                            info={
                              "Define content that can be updated from Blings platform, e.g - call-to-action url, marketing copy, etc"
                            }
                          />
                        </span>
                      ),
                      children: <LiveControlSetter />,
                    },
                  ]}
                />
              </ErrorBoundary>
            )}
          </ErrorBoundary>
        </Drawer>
      </div>
    </div>
  );
});
